import React from 'react'
import { RichText } from 'prismic-reactjs'
import { WhiteBox } from '../../components/core/whiteBox/WhiteBox'
import { Button } from '../../components/core/button/Button'
import { Section } from '../../components/core/section/Section'
import s from './ServiceCard.module.scss'
import { Text } from '../../components/core/text/Text'
import { usePlausible } from 'next-plausible'

const ServiceCard = ({ slice }) => {
    const plausible = usePlausible()
    return (
        <Section container>
            <WhiteBox className={s.whiteBox}>
                <div className={s.content}>
                    <Text variant="h2">
                        {typeof slice.primary.title !== 'string'
                            ? RichText.asText(slice.primary.title)
                            : slice.primary.title}
                    </Text>
                    <Text variant="p-18">
                        {typeof slice.primary.description !== 'string'
                            ? RichText.asText(slice.primary.description)
                            : slice.primary.description}
                    </Text>

                    {slice.primary.buttonText && (
                        <Button
                            className={s.button}
                            to={
                                slice.primary.buttonLink
                                    ? slice.primary.buttonLink
                                    : ''
                            }
                            variant="ghost"
                            onClick={() =>
                                plausible(
                                    'homepage-stofnaMinningarWhiteBox-button'
                                )
                            }
                        >
                            {slice.primary.buttonText}
                        </Button>
                    )}
                </div>
            </WhiteBox>
        </Section>
    )
}
export default ServiceCard
