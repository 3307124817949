// React&NextJS
import React, { useContext, useEffect } from 'react'
// Context&Auth
import { UIContext } from '../../../context/UIContext'
// 3rd party libraries
import { AnimatePresence, motion } from 'framer-motion'
// Services&Helper functions
import { useKeyDown } from '../../../hooks/useKeyDown'
// Styles
import s from './Share.module.scss'
// Components
import { Container } from '../../core/container/Container'
import { WhiteBox } from '../../core/whiteBox/WhiteBox'
import { Text } from '../../core/text/Text'
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
} from 'react-share'
import { Icon } from '../../core/icon/Icon'

type Props = {
    setOpenModal?: Function
    url: string
    isOpen?: Boolean
}

const ShareModal = ({ setOpenModal, url, isOpen }: Props) => {
    const { uiState, setUIState } = useContext(UIContext)
    const link = url ?? ''

    useEffect(() => {
        if (isOpen) {
            setUIState({ ...uiState, canScroll: false })
        } else {
            setUIState({ ...uiState, canScroll: true })
        }
        return () => {
            setUIState({ ...uiState, canScroll: true })
        }
    }, [isOpen])

    useKeyDown(['Escape'], ({ key }) => {
        if (key === 'Escape' && isOpen) {
            setOpenModal(false)
        }
    })

    const checkIfClosing = (e: React.BaseSyntheticEvent) => {
        if (e.target?.parentNode?.id === 'background') {
            setOpenModal(false)
        }
    }

    const closeModal = () => {
        setOpenModal(false)
    }

    if (isOpen) {
        return (
            // TODO: fix this
            // @ts-ignore
            <AnimatePresence>
                <motion.div
                    className={s.background}
                    onMouseDown={(e) => checkIfClosing(e)}
                    id="background"
                    animate={isOpen ? 'visible' : 'hidden'}
                    initial="hidden"
                    exit="hidden"
                    transition={{
                        duration: 0.2,
                        ease: 'easeOut',
                    }}
                    variants={{
                        visible: {
                            opacity: 1,
                        },
                        hidden: {
                            opacity: 0,
                        },
                    }}
                >
                    <Container className={s.container}>
                        <WhiteBox className={s.modal}>
                            <div
                                className={s.closeBtn}
                                onClick={() => closeModal()}
                            >
                                <Icon variant="close" />
                            </div>

                            <Text variant="date">Deila á</Text>
                            <div className={s.icons}>
                                <FacebookShareButton
                                    url={link}
                                    onClick={() => closeModal()}
                                >
                                    <FacebookIcon size={64} round={true} />
                                </FacebookShareButton>
                                <TwitterShareButton
                                    url={link}
                                    onClick={() => closeModal()}
                                >
                                    <TwitterIcon size={64} round={true} />
                                </TwitterShareButton>
                                {/* <FacebookMessengerShareButton
                                    url={link}
                                    onClick={() => closeModal()}
                                    appId={'291494419107518'}
                                    // appId={'943950006417962'}
                                >
                                    <FacebookMessengerIcon
                                        size={64}
                                        round={true}
                                    />
                                </FacebookMessengerShareButton> */}
                                <EmailShareButton url={link} separator={'\n'}>
                                    <EmailIcon
                                        size={64}
                                        round={true}
                                        onClick={() => closeModal()}
                                    />
                                </EmailShareButton>
                            </div>
                        </WhiteBox>
                    </Container>
                </motion.div>
            </AnimatePresence>
        )
    }
    return null
}

export default ShareModal
