// @ts-nocheck
// TODO: fix
// React&NextJS
import React, { useContext, useEffect } from 'react'
// Context&Auth
import { UIContext } from '../../../context/UIContext'
// 3rd party libraries
import { AnimatePresence, motion } from 'framer-motion'
// Services&Helper functions
import { useKeyDown } from '../../../hooks/useKeyDown'
// Styles
import s from './ShareModal.module.scss'
import classnames from 'classnames'
// Components
import { Container } from '../../core/container/Container'
import { WhiteBox } from '../../core/whiteBox/WhiteBox'
import { Text } from '../../core/text/Text'
import {
    EmailIcon,
    EmailShareButton,
    TwitterShareButton,
    FacebookShareButton,
    FacebookIcon,
    TwitterIcon,
} from 'react-share'
import { Memory, ScrapedFunerals } from '../../../types/types'
import { Icon } from '../../core/icon/Icon'
import { Image } from '../../core/image/Image'

const c = classnames.bind(s)

type Props = {
    isOpen: boolean
    closeModal: Function
    title: string
    info?: string
    data?: Memory | ScrapedFunerals
    isLoading?: boolean
    imgURL?: string
    published?: boolean
    link?: string
    email?: any
    [key: string]: unknown
}

const NewShareModal = ({
    isOpen,
    closeModal,
    title,
    info,
    isLoading,
    imgURL,
    children,
    published = false,
    link,
    email,
    data,
}: Props) => {
    const { uiState, setUIState } = useContext(UIContext)

    useEffect(() => {
        if (isOpen) {
            setUIState({ ...uiState, canScroll: false })
        } else {
            setUIState({ ...uiState, canScroll: true })
        }
        return () => {
            setUIState({ ...uiState, canScroll: true })
        }
    }, [isOpen])

    useKeyDown(['Escape'], ({ key }) => {
        if (key === 'Escape' && isOpen) {
            closeModal()
        }
    })

    const checkIfClosing = (e: React.BaseSyntheticEvent) => {
        if (e.target?.parentNode?.id === 'background') {
            closeModal()
        }
    }

    if (isOpen) {
        return (
            // TODO: fix this
            // @ts-ignore
            <AnimatePresence>
                <motion.div
                    className={c(s.funeralBG)}
                    onMouseDown={(e) => checkIfClosing(e)}
                    id="background"
                    animate={isOpen ? 'visible' : 'hidden'}
                    initial="hidden"
                    exit="hidden"
                    transition={{
                        duration: 0.2,
                        ease: 'easeOut',
                    }}
                    variants={{
                        visible: {
                            opacity: 1,
                        },
                        hidden: {
                            opacity: 0,
                        },
                    }}
                >
                    <Container className={s.container}>
                        <WhiteBox
                            className={c(s.modal_big, isLoading && s.loadModal)}
                        >
                            <div
                                className={s.closeBtn}
                                onClick={() => closeModal()}
                            >
                                <Icon variant="close" />
                            </div>
                            <div className={s.modalInfo}>
                                <div>
                                    <Text variant="h4">{title}</Text>
                                    {info && <Text variant="p">{info}</Text>}
                                    {children}
                                    {/* Share Icons */}
                                    {published && (
                                        <Text variant="date">Deila á</Text>
                                    )}
                                    <div className={s.icons}>
                                        {published ? (
                                            <>
                                                <FacebookShareButton
                                                    url={link}
                                                    onClick={() => closeModal()}
                                                >
                                                    <FacebookIcon
                                                        size={64}
                                                        round={true}
                                                    />
                                                </FacebookShareButton>
                                                <TwitterShareButton
                                                    url={link}
                                                    onClick={() => closeModal()}
                                                >
                                                    <TwitterIcon
                                                        size={64}
                                                        round={true}
                                                    />
                                                </TwitterShareButton>

                                                <EmailShareButton
                                                    url={link}
                                                    subject={email.subject}
                                                    body={email.body}
                                                    separator={'\n'}
                                                >
                                                    <EmailIcon
                                                        size={64}
                                                        round={true}
                                                        onClick={() =>
                                                            closeModal()
                                                        }
                                                    />
                                                </EmailShareButton>
                                            </>
                                        ) : (
                                            <EmailShareButton
                                                url={`https://minningar.is/memories/form?funeralId=${data?.objectID}`}
                                                subject={`Var ${data?.fullName} nákomin þér?`}
                                                body={`Sæl/l.
                                                <br>
                                                <br>
                                                Mig langar að benda þér á að hægt er að búa til fallega minningarsíðu á Minningar.is. Það er gott að minnast fólks og því væri gleðilegt ef ${data?.fullName} fengi þannig síðu og leyfa þannig minningunni að lifa. 
                                                <br>
                                                <br>
                                                Minningarsíðuna er hægt að stofna með einföldum hætti með því að smella hér: 
                                                `}
                                                separator=" "
                                            >
                                                <EmailIcon
                                                    size={48}
                                                    round={true}
                                                    onClick={() => closeModal()}
                                                />
                                            </EmailShareButton>
                                        )}
                                    </div>
                                </div>
                                <Image src={imgURL} height={400} alt="" />
                            </div>
                        </WhiteBox>
                    </Container>
                </motion.div>
            </AnimatePresence>
        )
    }
    return null
}

export default NewShareModal
